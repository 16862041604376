import { gql } from '@apollo/client/core';

export const DELIVERIES_NEW_QUERY = gql`
  query deliveriesQuery($businessId: ID, $supplierId: ID!, $date: ISODate!) {
    deliveries(businessId: $businessId, supplierId: $supplierId, fromDate: $date, toDate: $date) {
      nodes {
        id
        products {
          id
          productId
          quantity
          items {
            id
            productId
            quantity
          }
        }
        eventReferences {
          documentId
          reference
        }
        source {
          type
          ref
        }
        supplier {
          id
          name
        }
      }
    }
  }
`;

export const DELIVERY_PATCH_NEW_MUTATION = gql`
  mutation deliveryPatch($id: ID!, $data: DeliveryPatchInput) {
    deliveryPatch(id: $id, data: $data) {
      id
    }
  }
`;
