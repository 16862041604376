import { useDeliveryCreate } from './deliveryCreate';
import { useDeliveryItemCreate } from './deliveryItemCreate';
import { useDeliveryPatch } from './deliveryPatch';
import { useDeliveryItemPatch } from './deliveryItemPatch';
import { useDeliveryItemDelete } from './deliveryItemDelete';

function useDeliveryChange() {
  const { onDone: onDeliveryCreate } = useDeliveryCreate();
  const { onDone: onDeliveryItemCreate } = useDeliveryItemCreate();
  const { onDone: onDeliveryPatch } = useDeliveryPatch();
  const { onDone: onDeliveryItemPatch } = useDeliveryItemPatch();
  const { onDone: onDeliveryDelete } = useDeliveryItemDelete();

  return {
    onChange: (callback) => {
      onDeliveryCreate(callback);
      onDeliveryItemCreate(callback);
      onDeliveryItemPatch(callback);
      onDeliveryPatch(callback);
      onDeliveryDelete(callback);
      onDeliveryCreate(callback);
    },
  };
}

export {
  useDeliveryCreate,
  useDeliveryItemCreate,
  useDeliveryPatch,
  useDeliveryItemPatch,
  useDeliveryItemDelete,
  useDeliveryChange,
};
