import { computed } from 'vue';
import { gql } from '@apollo/client/core';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useDeliveryItems(variables) {
  const { error } = useNotification();

  const { result, loading: queryLoading, onError, refetch } = useQuery(DELIVERY_ITEMS_QUERY, variables);
  const deliveryItems = computed(() => (result.value ? result.value.deliveryItems?.nodes ?? [] : []));
  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('useDeliveryItems', err);
    error();
  });

  return {
    deliveryItems,
    loading,
    refetch,
  };
}

const DELIVERY_ITEMS_QUERY = gql`
  query deliveryItems($businessId: ID, $productId: ID, $fromDate: ISODate, $toDate: ISODate) {
    deliveryItems(businessId: $businessId, productId: $productId, fromDate: $fromDate, toDate: $toDate) {
      nodes {
        id
        productId
        quantity
        deliveryId
        deliveryDate
        difference {
          customerValue
          supplierValue
        }
        deliverySource {
          type
          ref
        }
      }
    }
  }
`;
