import { gql } from '@apollo/client/core';
import { useMutation } from '@vue/apollo-composable';
import { onUnmounted } from 'vue';

const onDoneSubscribedCallbacks = [];
const onErrorSubscribedCallbacks = [];

export const useDeliveryItemPatch = () => {
  const { mutate, onDone, onError } = useMutation(DELIVERY_ITEM_PATCH_MUTATION);
  onDone((result) => {
    onDoneSubscribedCallbacks.forEach((cb) => cb(result));
  });

  onError((result) => {
    onErrorSubscribedCallbacks.forEach((cb) => cb(result));
  });

  const onDoneCallbacks = [];
  const onErrorCallbacks = [];

  onUnmounted(() => {
    onDoneCallbacks.forEach((cb) => onDoneSubscribedCallbacks.splice(onDoneSubscribedCallbacks.indexOf(cb), 1));
    onErrorCallbacks.forEach((cb) => onErrorSubscribedCallbacks.splice(onErrorSubscribedCallbacks.indexOf(cb), 1));
  });

  return {
    deliveryItemPatch: mutate,
    onDone: (callback) => {
      onDoneCallbacks.push(callback);
      onDoneSubscribedCallbacks.push(callback);
    },
    onError: (callback) => {
      onErrorCallbacks.push(callback);
      onErrorSubscribedCallbacks.push(callback);
    },
  };
};

export const DELIVERY_ITEM_PATCH_MUTATION = gql`
  mutation deliveryItemPatch($deliveryId: ID!, $deliveryItemId: ID!, $data: DeliveryItemPatchInput) {
    deliveryItemPatch(deliveryId: $deliveryId, deliveryItemId: $deliveryItemId, data: $data) {
      id
    }
  }
`;
