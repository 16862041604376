import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';

import { useNotification, useLoading } from '@/modules/core';

export function useDeliveries(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    onError,
    refetch,
  } = useQuery(DELIVERIES_QUERY, variables, () => ({
    enabled: !!variables.value.businessId && !!variables.value.orderIds?.length,
  }));

  const deliveries = computed(() => (result.value ? result.value.deliveries?.nodes ?? [] : []));

  const loading = useLoading(queryLoading, variables);

  onError((err) => {
    console.error('delivery - useDeliveries', err);
    error();
  });

  return {
    deliveries,
    loading,
    refetch,
  };
}

export const DELIVERIES_QUERY = gql`
  query deliveries($businessId: ID!, $orderIds: [ID]) {
    deliveries(businessId: $businessId, orderIds: $orderIds) {
      nodes {
        id
        eventReferences {
          documentId
        }
      }
    }
  }
`;
