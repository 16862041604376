import { gql } from '@apollo/client/core';
import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';

import { useNotification, useLoading } from '@/modules/core';

export function useDeliveryTasks(variables) {
  const { error } = useNotification();

  const {
    result,
    loading: queryLoading,
    refetch,
    onError,
  } = useQuery(TASKS_QUERY, variables, () => ({
    enabled: !!variables.value.businessId,
  }));

  const totalCount = computed(() => result.value?.tasksNew2?.totalCount ?? 0);
  const tasks = computed(() => result.value?.tasksNew2?.nodes ?? []);
  const loading = useLoading(queryLoading, variables);

  onError((param) => {
    console.error('useDeliveryTasks', param);
    error();
  });

  return {
    tasks,
    totalCount,
    loading,
    refetch,
  };
}

const TASKS_QUERY = gql`
  query deliveryTasks($businessId: ID!, $limit: Int, $offset: Int) {
    tasksNew2(businessId: $businessId, domain: "delivery", limit: $limit, offset: $offset, completed: false) {
      totalCount
      nodes {
        id
        businessId
        delivery {
          document {
            id
            totalAmount
            issueDate
            items {
              productId
            }
            type
            documentNumber
            filePathUrl
            supplier {
              name
              id
            }
          }
        }
      }
    }
  }
`;
